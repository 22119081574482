import React from 'react';
import { Script } from 'gatsby';

export default (
  <>
    <Script async src="https://www.googletagmanager.com/gtag/js?id=AW-378758560"></Script>
    <Script>
      {`window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'AW-378758560');`}
    </Script>
    <Script>
      {`(function() {
  if (typeof window === 'undefined') return;
  if (typeof window.signals !== 'undefined') return;
  var script = document.createElement('script');
  script.src = 'https://cdn.cr-relay.com/v1/site/d2697d0e-66dd-4893-931e-b6beb2aabcde/signals.js';
  script.async = true;
  window.signals = Object.assign(
    [],
    ['page', 'identify', 'form'].reduce(function (acc, method){
      acc[method] = function () {
        signals.push([method, arguments]);
        return signals;
      };
     return acc;
    }, {})
  );
  document.head.appendChild(script);
})();`}
    </Script>
  </>
);
